
@media (max-width: 420px)  { 

    .container {
        width: 100vw;
        height: 30vh;
        display: flex;
        margin: auto;
    }


    /* temp */
    .temp {
        width: auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
    }

    /* Partly Cloudy */
    .desc {/* Partly Cloudy */
        text-transform: capitalize;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        /* identical to box height */
        
        color: white;
        
        }
        
        

    /* identical to box height */
    .box {color: #FFFFFF;
    }

    .left {
        flex: 60%;
    }

    .right {
        flex: auto;
        width: auto;
        display: block;
    }

    img {
        position: relative;
        height: 130px;
        width: auto;
        justify-content: center;
        align-items: center;
        top: 55px;
    }
}

.container {
    width: auto;
    height: 30vh;
    display: flex;
    margin: auto;
}


/* temp */
.temp {
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
}

/* Partly Cloudy */
.desc {/* Partly Cloudy */
    text-transform: capitalize;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    /* identical to box height */
    
    color: white;
    
    }


/* identical to box height */
.box {color: #FFFFFF;
}

.left-main {
    flex: 60%;
}

.right-main {
    flex: auto;
    width: auto;
    display: block;
}

.img-main {
    position: relative;
    height: 130px;
    width: auto;
    justify-content: center;
    align-items: center;
    top: 55px;
}

@media (max-width:431px) {
    .container {
        width: auto;
        height: 30vh;
        display: flex;
        margin: auto;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
}
