.forecast-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    background: rgba(16, 16, 16, 0.1);
    box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
    border-radius: 17px;
}

.forecast {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    padding: 0.5em;
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    margin-bottom: 20px; 
}

.group-35 {
    width: 100%;
    height: auto;
}

.forecast-table {
    width: 100%;
    border-collapse: collapse;
}

.forecast-table th,
.forecast-table td {
    text-align: center;
    padding: 10px;
    color: #FFFFFF;
}

.forecast-table td:first-child,
.forecast-table th:first-child {
    text-align: left;
}

@media (max-width: 431px) {
    .forecast-container {
        position: relative;
        display: block;
        width: 95%;
        margin: auto;
        height: auto;
        background: rgba(16, 16, 16, 0.1);
        box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
        border-radius: 17px;
    }
}