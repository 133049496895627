/* Rectangle 14 */

@media (max-width: 431px) {
    .warning {
        position: relative;
        display: flex;
        margin: auto;
        width: 90%;
        height: auto;

        background: rgba(16, 16, 16, 0.1);
        box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
        border-radius: 17px;
    }

    .warning div {
        position: relative;
        margin: auto;
    }

    svg {
        margin: auto;
    }
}

/* desktop view */
.warning {
    position: relative;
    display: flex;
    width: 90%;
    height: 150px;

    background: rgba(16, 16, 16, 0.1);
    box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
    border-radius: 17px;
}

.warning div {
    position: relative;
    margin: auto;
}

svg {
    margin: auto;
}