@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.sos{
  position: relative;
  width: auto;
  height: 285px;

  background: rgba(16, 16, 16, 0.1);
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
  border-radius: 17px;
}

.get-help{
  padding: 20px 20px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #FFFFFF;
}


input[type=text] {
  width: 80%;
  height: 140px;

  background: rgba(16, 16, 16, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.3);
  border-radius: 17px;
  
  margin: 0px 20px !important;
}

button{
  width: 145px;
  height: 45px;

  background: #F53B00;
  color: #FFFFFF;
  border-radius: 8px;
  margin: 15px 10px;

}

a{
  text-decoration: none;
  color: #FFFFFF;
}

@media (max-width:431px) {
  .sos{
    position: relative;
    width: auto;
    height: auto;
    margin: 1em;
    background: rgba(16, 16, 16, 0.1);
    box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
    border-radius: 17px;
  }
}

