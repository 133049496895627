.weather-div {
    width: 90%;
}

  .box1 {
    width: 80%;
    height: 6em;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 20px;
  
    background: rgba(16, 16, 16, 0.1);
    box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
    border-radius: 17px;
  }
  .p1{
    font-size: .8em;
    font-weight: 800;
  }
  .p2{
    font-size: 1.3em;
  }
  .box-holder {
    width: 90%;
    display: grid;
    margin: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px;
    flex: 65%;
  }

@media (max-width:431px) {
    .box1 {
        width: 30vw;
        height: 9em;
        display: flex;
        margin: auto;
        flex-direction: column;
        justify-content: none;
        align-items: center;
        padding: 10px;
        margin: 20px;
        
        background: rgba(16, 16, 16, 0.1);
        box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
        border-radius: 17px;
    }
    .p1{
        font-size: 1em;
        font-weight: 800;
    }
    .p2{
        font-size: 2em;
    }
    .box-holder {
        width: 90vw;
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0px;
    } 

    .weather-div {
        width: 100%;

    }
}