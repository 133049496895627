.App {
  text-align: center;
  zoom: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 431px) {
  .desktop {
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: auto;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .desktop::-webkit-scrollbar {
    display: none;
  }

  .night {
    /* Desktop Frame */
    background: linear-gradient(205.41deg, #21205B 11.26%, rgba(117, 115, 198, 0.76) 83.9%);
  }

  .light {
    background: linear-gradient(205.41deg, #21205B 11.26%);
  }

  .eclipse-top {
    /* Ellipse 42 */
    position: absolute;
    width: 914px;
    height: 873px;
    right: -20em;
    bottom: -30em;

    background: rgba(253, 173, 255, 0.33);
    filter: blur(166.65px);
  }

  .eclipse-btm {
    /* Ellipse 40 */
    position: absolute;
    width: 914px;
    height: 873px;
    right: -15em;
    top: -30em;

    background: rgba(127, 67, 255, 0.33);
    filter: blur(166.65px);
  }

  .left {
    margin: 2em;
    width: 30vw;
    flex: 50%;
  }

  .right {
    margin: 2em;
    width: 70vw;
    height: 100vh;
    flex: 50%;
  }

  .bottom {
    margin: 2em;  
  }
}

@media (max-width:430px) {
  .night {
    /* Desktop Frame */
    background: linear-gradient(205.41deg, #21205B 11.26%, rgba(117, 115, 198, 0.76) 83.9%);
  }

  .light {
    background: linear-gradient(205.41deg, #21205B 11.26%);
  }

  .eclipse-top {
    /* Ellipse 42 */
    position: absolute;
    width: 914px;
    height: 873px;
    right: -20em;
    bottom: -30em;

    background: rgba(253, 173, 255, 0.33);
    filter: blur(166.65px);
  }

  .eclipse-btm {
    /* Ellipse 40 */
    position: absolute;
    width: 914px;
    height: 873px;
    right: -15em;
    top: -30em;

    background: rgba(127, 67, 255, 0.33);
    filter: blur(166.65px);
  }

  .desktop {
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: auto;
    overflow-x: hidden;
  }

  .desktop::-webkit-scrollbar {
    display: none;
  }

  .left {
    margin: auto;
    width: 100%;
    flex: 0;
  }

  .right {
    margin: auto;
    width: 100%;
    height: 100%;
    flex: 0;
  }

}
