.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 110;
}

.location {
  margin: 10px 0px;
  z-index: 110;
}

.menu-bnt{
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 20px;
  z-index: 110;
}

.menu-line{
  width: 30px;
  height: 4px;
  background: white;
  border-radius: 3px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
}

.open > .l1{
  transform: 
    translate(0px, 11px) 
    rotate(405deg);
}
.open > .l3{
  transform: 
    translate(0px, -11px) 
    rotate(135deg);
}
.open > .l2{
  display: none;
}

.menu{
  background: rgba(74, 74, 74, 0.54);
  backdrop-filter: blur(32.3px);
  z-index: 100;
  /* Note: backdrop-filter has minimal browser support */

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
}

.card-holder {
  margin: 100px 10px;
}
.loca-card{
  width: 400px;
  height: 104px;

  background: rgba(217, 217, 217, 0.11);
  border-radius: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
  position: relative;
}
.loca-card>p{
  font-size: 41px;
  font-weight: 700;
}
.loca-name{
  font-size: 21px;
  font-weight: 700;
}
.plus-card{
  display: flex;
  justify-content: center;
  align-items: center;
}
.plus{
  width: 50px;
  height: 50px;
  color: aliceblue;
  background: rgb(255, 255, 255);
  clip-path: polygon(40.6% 0.3%, 60.1% 0%, 60.1% 39.8%, 100% 39.5%, 100% 58.3%, 60.6% 58.5%, 60.8% 100%, 40.1% 100%, 40.1% 58.8%, 0% 58.8%, 0% 40.3%, 40.6% 40%);
}

.delete-btn{
  position: absolute;
  top: 16px;
  right: 8px;
  cursor: pointer;
}
.delete-btn>.menu-line{
  width: 15px;
  height: 2px;
}
.delete-btn>.l1{
  transform: 
    translate(0px, 1px) 
    rotate(405deg);
}
.delete-btn>.l2{
  transform: 
    translate(0px, -1px) 
    rotate(135deg);
}

.search-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  background: rgba(217, 217, 217, 0.11);
  padding: 30px;
  border-radius: 24px;
  z-index: 150;
}

.close{
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

@media (max-width:431px) {
  .card-holder {
    margin: 100px 0px;
  }   
  .loca-card{
    width: 370px;
    height: 104px;
  }
  .search-popup{
    top:550px;
    left: 0px;
  }
}