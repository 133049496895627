.hourlyContainer {
  width: 90%; 
  height: auto;
  white-space: nowrap; /* Prevent wrapping of items */
  position: relative; 
  margin-bottom: 1em;

  background: rgba(16, 16, 16, 0.1);
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
  border-radius: 17px;
}

.hourlyList {
  overflow-x: scroll;
  display: flex;
  gap: 1em;
}

.hourlyList::-webkit-scrollbar {
  display: none;
}

.hourlyItem {
  height: auto;
  justify-content: center;
  border-radius: 5px;
  padding: 0.25em;
  text-align: center;
}

.icon {
  height: auto;
}

.img-hf {
    text-align: center;
    justify-content: center;
    align-content: center;
    width: 5em;
    height: auto;
}

.hourlyTitle {
  margin: auto;
  position: sticky; 
  padding: 10px; 
  color: #fff; 
  z-index: 1; 
}

.hr {
  display: flex;
}

.svg-hr {
  margin-left: 1em;
  margin-right: 1em;
  width: 20em;
  height: auto;
}

.HR {
  width: 90%; 
  margin-bottom: 1em;
  height: auto;
  position: relative; 

  background: rgba(16, 16, 16, 0.1);
  box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
  border-radius: 17px;
  padding: 0%;
}

@media (max-width:431px) {
  .hourlyContainer {
    width: 95%; 
    margin: auto;
    margin-bottom: 1em;
  }

  .HR {
    width: 95%; 
    margin-bottom: 1em;
    height: auto;
    position: relative; 
    margin: auto;
  
    background: rgba(16, 16, 16, 0.1);
    box-shadow: 0px 0px 30px -2px rgba(0, 0, 0, 0.5);
    border-radius: 17px;
    padding: 0%;
  }

  .svg-hr {
    margin-left: 1em;
    margin-right: 1em;
    width: 56.5em;
    height: auto;
  }
}